import { useTranslation } from 'next-i18next';
import React from 'react';
import Header from '@/components/header';
import { ILinkTabs, LinkTabs } from '@/components/input';
import Offline from '@/components/offline';
import PageLoader from '@/components/page-loader';

export interface ILayoutProps {
	children?: any;
	title?: string;
	tabs?: Array<ILinkTabs>;
	back?: boolean;
	logout?: boolean;
	createAssignment?: boolean;
	searchAssignment?: boolean;
}

export default function Layout({ children, tabs, title, back = true, logout = false, createAssignment = false, searchAssignment = false }: ILayoutProps) {
	const { t } = useTranslation();
	const langTitle = title ? t(title) ?? '' : '';

	return (
		<>
			<div className="z-40 sticky top-0 bg-background shadow-sm">
				<div className="shadow-sm">
					<Header
						title={langTitle}
						back={back}
						logout={logout}
						createAssignment={createAssignment}
						searchAssignment={searchAssignment}
					/>
					<Offline />
				</div>
				<div className="flex flex-col">
					{tabs && (
						<LinkTabs
							value={tabs}
							className="m-2"
						/>
					)}
				</div>
			</div>
			<PageLoader />
			<main className="flex flex-col grow bg-background">{children}</main>
		</>
	);
}
