import { StatusCodes } from 'http-status-codes';
import { del, keys } from 'idb-keyval';
import { NextApiRequest } from 'next';
import { getSession, signOut } from 'next-auth/react';
import { i18n } from 'next-i18next';
import { ErrorResponse } from '@/classes';
import { NextApiRequestWithUser } from '@/middleware/add-user';
import { AlertFunction } from '@/utils/alert/alert-context';

// export async function printBarcode (serial:any, setAlert?:Function, errorMessage?: string|null, successMessage?: string|null) {
//     try {

//         // Create a new instance of the object
//         const browserPrint =  new ZebraBrowserPrintWrapper();

//         // Select default printer
//         //const defaultPrinter = await browserPrint.getDefaultPrinter();
//         //browserPrint.setPrinter(defaultPrinter);

//         const availablePrinters: Array<any> =  await browserPrint.getAvailablePrinters();

//         if (!availablePrinters?.length) {
//             throw new Error(/*t('no-printer') ?? */'No available printers found.');

//         }

//         // Set the printer
//         browserPrint.setPrinter(availablePrinters[0]);

//         // Check printer status
//         const printerStatus = await browserPrint.checkPrinterStatus();

//         // Check if the printer is ready
//         if (printerStatus.isReadyToPrint) {

//             // ZPL script to print a simple barcode
//             // const zpl = `^XA
//             //             ^BY2,2,100
//             //             ^FO20,20^BC^FD${serial}^FS
//             //             ^XZ`;

//             const zpl =`^XA
//             ^MMT
//             ^MNM
//             ^FO50,50,0^A0,30^TB,500,300^FD${serial}
//             ^XZ`;

//             browserPrint.print(zpl);
//         } else {
//             console.log("Error/s", printerStatus.errors);
//             throw new Error('Printer is not reacy to print.');
//             //alert(printerStatus.errors)
//         }

//         if (setAlert && successMessage) {
//             setAlert('success', successMessage)
//         }

//     } catch (error:any) {

//         if (setAlert) {
//             setAlert('error', errorMessage ?? `Error: ${error.status ?? 'unknown status'} - ${error.message}`);
//         }
//         else {
//             throw error;
//         }
//     }
// };

export const fileToBase64 = (file: File | Blob): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			resolve(reader.result as string);
		};
		reader.readAsDataURL(file);
		reader.onerror = reject;
	});

export function getDate(date: Date) {
	return date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
}

export function localizeDate(dateInput: string | Date): Date {
	return typeof dateInput === 'string' ? new Date(dateInput.split('.').shift() ?? dateInput) : dateInput;
}

export function updateQueryParam(router: any, param: string, value: any) {
	const url = {
		pathname: router.pathname,
		query: { ...router.query, [param]: value },
	};
	router.push(url, undefined, { shallow: true });
	// router.replace({
	//     query: { ...router.query, [param]: value },
	// });
}

export function localeDate(date: Date | string, lang?: string | null, options?: Intl.DateTimeFormatOptions) {
	const dateOptions: Intl.DateTimeFormatOptions = options ?? { year: 'numeric', month: '2-digit', day: '2-digit' };
	let usableDate: Date;

	if (!date) {
		return date;
	} else if (!(date instanceof Date)) {
		usableDate = new Date(date);
	} else {
		usableDate = date;
	}

	return usableDate.toLocaleDateString(lang ?? undefined, dateOptions);
}

export function partition<T>(data: Array<T>, callback: (child: T) => boolean) {
	return data?.reduce(
		(acc: Array<Array<T>>, curr) => {
			acc[callback(curr) ? 0 : 1].push(curr);
			return acc;
		},
		[[], []],
	);
}

export function getRandomColor() {
	const maxVal = 0xffffff; // 16777215
	const randomNumber: number = Math.floor(Math.random() * maxVal);
	const rndColor: string = randomNumber.toString(16).padStart(6, '0').toUpperCase();

	return `#${rndColor}`;
}

// export function sortOnDate(items: Array<any>, prop: any, invert: boolean = false): any[] {

//     if (items?.length) {

//         if (typeof items[0][prop] === 'string') {

//         }

//         const sortedArray = invert ? items.sort((a, b) => a[prop].diff(b[prop])) : items.sort((a, b) => b[prop].diff(a[prop]));

//         return sortedArray;
//         // return items.sort((a, b) => {
//         //     const dateA = Date.parse(a[prop]);
//         //     const dateB = Date.parse(b[prop]);

//         //     if (invert) {
//         //         return dateA - dateB;
//         //     }
//         //     else {
//         //         return dateB - dateA;
//         //     }
//         // })
//     }
//     else {
//         return [];
//     }
// }

export function sortAlphabetically(items: Array<any>, prop: string, extraFilter?: (item1: any, item2: any) => number): Array<any> {
	if (items?.length) {
		return items.sort(function (a, b) {
			const filterVal = extraFilter ? extraFilter(a, b) : false;

			if (typeof filterVal === 'number' && filterVal) {
				return filterVal;
			} else {
				return a[prop]?.localeCompare(b[prop], undefined, {
					numeric: true,
					sensitivity: 'base',
				});
			}
		});
	} else {
		return [];
	}
}

export function postError(req: NextApiRequest | NextApiRequestWithUser | undefined, error: any, at: string) {
	// const request = new Request(`${process.env.API_BASE_URL}Debug/log`, {
	//     method: 'POST',
	//     body: JSON.stringify({
	//         at: at,
	//         error: error,
	//         req: {
	//             body: req?.body,
	//             status: req?.statusCode,
	//             msg: req?.statusMessage,
	//             url: req?.url
	//         }
	//     })
	// });
	// fetch(request, { cache: 'no-store' });
}

export async function fetcher(
	url: RequestInfo | URL,
	setAlert?: AlertFunction,
	errorMessage?: string | null,
	successMessage?: string | null,
	infoMessage?: string | null,
) {
	//i18n?.init(); // Initialize i18n

	try {
		const resp = await fetch(url);

		if (!resp.ok) {
			if (resp.status === 401 || resp.status === 403) {
				// const session:any = await getSession();
				// const logoutUri = session ? `${process.env.OKTA_OAUTH2_ISSUER}/v1/logout?id_token_hint=${session?.idToken}&post_logout_redirect_uri=${process.env.BASE_URL}` : undefined;
				// signOut({callbackUrl: logoutUri});

				await signOut({ callbackUrl: '/api/auth/logout' });
			}

			const data: ErrorResponse = {
				status: resp.status,
				message: await resp.text(),
				url: resp.url,
			};

			throw data;
		}

		const data = await resp.json();

		if (setAlert) {
			if (infoMessage) {
				setAlert('info', infoMessage);
			}
			if (successMessage) {
				setAlert('success', successMessage);
			}
		}

		return data;
	} catch (error: any) {
		if (errorMessage != 'fetch failed') {
			if (setAlert) {
				if (error.status === StatusCodes.NOT_ACCEPTABLE) {
					setAlert('error', i18n?.t('error-not-acceptable') ?? 'request was not acceptable');
				} else {
					setAlert('error', errorMessage ?? `Error: ${error.status ?? 'unknown status'} - ${error.message}`);
				}
			} else {
				throw error;
			}

			return false;
		}
	}
}

export async function postData(url: string, data: any, contentType?: string) {
	if (!url?.length || !data) {
		throw new Error('missing required properties');
	}

	const req: RequestInit = {
		method: 'POST', // *GET, POST, PUT, DELETE, etc.
		mode: 'no-cors', // no-cors, *cors, same-origin
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		//credentials: 'same-origin', // include, *same-origin, omit
		headers: {
			'Content-Type': contentType ?? 'application/json',
			// 'Content-Type': 'application/x-www-form-urlencoded',
		},
		//redirect: 'follow', // manual, *follow, error
		//referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		body: contentType ? data : JSON.stringify(data), // body data type must match "Content-Type" header
	};

	// Default options are marked with *
	const response = await fetch(url, req);
	return await (contentType ? response.text() : response.json()); // parses JSON response into native JavaScript objects
}

export function removeItems<T>(arr: Array<T>, values: Array<T>): Array<T> {
	return arr.filter((item) => !values.includes(item));
}

export function getFirstItem(prop: any) {
	if (Array.isArray(prop)) {
		return prop[0];
	} else {
		return prop;
	}
}

export async function copyToClipboard(text: string) {
	if ('clipboard' in navigator) {
		await navigator.clipboard.writeText(text);
	} else {
		document.execCommand('copy', true, text);
	}
}

export const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || '';

export const removeLocalAssignmentData = async (assignmentID: string) => {
	for (const key of await keys()) {
		if (String(key).includes(assignmentID)) {
			await del(key);
		}
	}
};
