//const path = require('path');
//const { initReactI18next } = require('react-i18next');
module.exports = {
	//debug: process.env.NODE_ENV === 'development',
	i18n: {
		locales: ['nl'],
		defaultLocale: 'nl',
		//localeDetection: false,
	},
	localePath: typeof window === 'undefined' ? require('path').resolve('./public/locales') : '/locales',
	react: { useSuspense: false },
	// serializeConfig: false,
	// use: [ initReactI18next ]
};
