import { XMarkIcon } from '@heroicons/react/24/solid';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { ModalProps } from '@/types/modal_props';
import { Modal as ModalUtil } from 'utils/modal';

export const ModalComponent = forwardRef(function ModalComponent(propsValues: ModalProps, ref) {
	const {
		component: RenderInner,
		props,
		modalId = 'modal',
		closable = true,
		onClose = () => undefined,
		closeModal = () => undefined,
		onConfirm,
		onCancel,
		isVisible,
		width,
		title,
		subTitle,
		className = '',
		modalFooter,
		modalHeader,
		scrollable = false,
		//closeable = true,
		closeIcon = false,
		centered = true,
	} = propsValues;

	const onModalClose = useCallback(
		(isClose: any) => {
			if (!closable) return;
			if (isClose) {
				closeModal();
				onClose();
			}
			ModalUtil.close();
		},
		[closable, closeModal, onClose],
	);

	const wrapperRef = useRef<any>(null);
	const [page, setPage] = useState(1);

	useEffect(() => {
		function handleClickOutside(event: any) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				onModalClose(closable);
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [closable, onModalClose, wrapperRef]);

	return (
		<div className="modal-mask fixed inset-0 h-full w-full bg-black/30 z-50 flex items-center justify-center">
			<dialog
				open
				ref={wrapperRef}
				className={`modal-container flex flex-col gap-4 w-full max-h-full ${!width && 'max-w-md'} p-6 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl relative ${className}`}
			>
				<div className="flex flex-col gap-2 modal-header">
					<div className="flex justify-between items-center">
						<h3 className="text-lg font-medium leading-6 text-neutral-lighter">{String(title)}</h3>
						{modalHeader && <div>{modalHeader}</div>}
						{closeIcon && closable && (
							<button onClick={() => onModalClose(closable)}>
								<XMarkIcon className="w-5 h-5" />
							</button>
						)}
					</div>
					{subTitle && <p className="text-l text-gray-500">{String(subTitle)}</p>}
				</div>

				<div className={`modal-content flex flex-col grow h-full max-h-full ${scrollable ? 'overflow-auto' : 'overflow-hidden'}`}>
					{RenderInner && (
						<RenderInner
							inModal={true}
							{...props}
						/>
					)}
				</div>

				{modalFooter && (
					<div className="modal-footer">
						<div className="flex w-full gap-2">{modalFooter}</div>
					</div>
				)}
			</dialog>
		</div>
	);
});
