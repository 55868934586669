import { SignalSlashIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { useGlobalContext } from '@/lib/global-context';

export default function Offline() {
	const { t } = useTranslation();
	const isOnline = useGlobalContext().online;

	return (
		<>
			{!isOnline ?
				<div className="py-2 px-4 z-[99] bg-sky-200 text-neutral text-center shadow-sm w-full flex items-center justify-center gap-2">
					{/*<GlobeEuropeAfricaIcon className="w-5 h-5"/>*/}
					<SignalSlashIcon className="w-5 h-5" />
					<span>{t('offline-banner')}</span>
				</div>
			:	<div />}
		</>
	);
}
