import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/solid';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { signIn, signOut, useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { ReactElement, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useGlobalContext } from '@/lib/global-context';
import BackButton from './back-button';

export default function Header(props: {
	title: string;
	back?: boolean;
	logout?: boolean;
	login?: boolean;
	createAssignment?: boolean;
	searchAssignment?: boolean;
}): ReactElement {
	const { t, i18n } = useTranslation('common');
	const { pageTitle, setPageTitle, online } = useGlobalContext();
	const { data: session, status }: any = useSession();
	const router = useRouter();

	//const [currentDate, setCurrentDate] = useState(localeDate(new Date()));

	useEffect(() => {
		//setCurrentDate(localeDate(new Date(), i18n?.resolvedLanguage));

		if (props.title?.length) {
			setPageTitle(props.title);
		}

		setPageTitle(props.title?.length ? props.title : pageTitle);
	}, [i18n, pageTitle, props.title, setPageTitle]);

	return (
		<header className={'bg-primary text-neutral-light flex flex-wrap gap-5 items-center justify-center py-3 px-4'}>
			<div className="flex flex-1 gap-5 items-center">
				{props.login && (
					<>
						<button onClick={() => signIn('okta', { callbackUrl: '/' })}>{t('sign-in')}</button>
					</>
				)}
				{!!(props.logout || props.back) && (
					<>
						{session && props.logout && (
							<button
								type="button"
								onClick={() =>
									signOut({
										callbackUrl: `${process.env.OKTA_OAUTH2_ISSUER}/v1/logout?id_token_hint=${session?.idToken}&post_logout_redirect_uri=${process.env.BASE_URL}`,
									})
								}
								data-cy="header-logout"
							>
								<ArrowRightOnRectangleIcon className="h-8 w-8" />
							</button>
						)}
						{props.back && <BackButton />}
					</>
				)}
			</div>
			<p className="text-center">{pageTitle}</p>
			<div className="flex flex-1 justify-end gap-5 items-center">
				{props.createAssignment && online && (
					<Link
						href={'/create/assignment'}
						className="text-primary bg-neutral-light p-2 rounded border border-neutral-light-200 text-center flex items-center justify-center text-sm font-semibold"
					>
						{t('create-assignment')}
					</Link>
				)}
				{props.searchAssignment && (
					<Link href="/search">
						<MagnifyingGlassIcon className="w-8 h-8" />
					</Link>
				)}
				<Link href="/">
					<SVG
						src="/logo.svg"
						title={process.env.NEXT_PUBLIC_APP_NAME ?? 'logo'}
						width={50}
						height={50}
						className="justify-self-center w-auto fill-white"
						//priority={true}
					/>
				</Link>
			</div>
		</header>
	);
}
