import { CheckIcon } from '@heroicons/react/24/solid';
import { CSSInterpolation, Theme, createTheme, buttonClasses } from '@mui/material';
import { Modal as PackageModal, ModalWrapper as PackageModalWrapper } from 'iit-ui';
import { Context, createContext, useContext, useEffect, useState } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import { ModalWrapper } from '@/components/modal-v2';
import { theme as tailwindTheme, content as tailwindContent } from 'tailwind.config';
import { Modal } from 'utils/modal';

const tailwindConfig = resolveConfig({ content: tailwindContent, theme: tailwindTheme });
const colors = tailwindConfig.theme?.colors as { [key: string]: string };

const defaultTheme: Theme = createTheme({
	palette: {
		primary: {
			main: colors.primary['DEFAULT' as keyof typeof colors.primary] as string,
			light: colors.primary['light' as keyof typeof colors.primary] as string,
		},
		secondary: {
			main: colors.neutral['light' as keyof typeof colors.neutral] as string,
			light: colors.neutral['light-100' as keyof typeof colors.neutral] as string,
		},
		// action: {
		//     disabledBackground: colors.neutral['light-200' as keyof typeof colors.neutral] as string,
		// }
	},
	components: {
		MuiAccordion: {
			defaultProps: {
				disableGutters: true,
			},
			styleOverrides: {
				root: ({ ownerState }) =>
					({
						boxShadow: 'none',
						background: 'none',
						display: 'flex',
						flexDirection: 'column',
						'&.Mui-expanded': {
							gap: tailwindConfig.theme?.gap?.['3'],
							//color: colors['sky']['900']
						},
						// '&.Mui-disabled': {
						//     background: 'none',
						//     opacity: 1
						// },
						'&::before': {
							background: 'none',
						},
						// ...(ownerState.disabled === true && {
						//     backgroundColor: 'yellow !important',
						//     color: '#fff',
						// }),
					} as CSSInterpolation),
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: ({ ownerState }) =>
					({
						backgroundColor: colors.neutral['light-100' as keyof typeof colors.neutral],
						color: colors.neutral['DEFAULT' as keyof typeof colors.neutral],
						borderRadius: tailwindConfig.theme?.borderRadius?.lg,
						minHeight: '4.5rem',
						padding: tailwindConfig.theme?.padding?.['4'],
						shadow: tailwindConfig.theme?.boxShadow?.md,
						fontSize: tailwindConfig.theme?.fontSize?.md,
						'&:hover': {
							backgroundColor: colors.neutral['light-200' as keyof typeof colors.neutral],
						},
					} as CSSInterpolation),
				content: ({ ownerState }) =>
					({
						margin: 0,
					} as CSSInterpolation),
			},
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: ({ ownerState }) =>
					({
						padding: tailwindConfig.theme?.padding?.['4'],
						borderRadius: tailwindConfig.theme?.borderRadius?.lg,
						boxShadow: tailwindConfig.theme?.boxShadow?.['neumorphic-light'],
						//marginTop: tailwindConfig.theme?.margin?.['3'],
					} as CSSInterpolation),
			},
		},
		MuiButtonBase: {
			styleOverrides: {
				root: ({ ownerState, theme }) =>
					({
						'&.Mui-disabled': {
							backgroundColor: colors.neutral['light-200' as keyof typeof colors.neutral],
							color: colors.neutral['light' as keyof typeof colors.neutral],
							opacity: '1 !important',
						},
					} as CSSInterpolation),
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: ({ ownerState }) =>
					({
						marginLeft: 0,
						userSelect: 'none',
					} as CSSInterpolation),
			},
		},
		MuiCheckbox: {
			defaultProps: {
				checkedIcon: (
					<div className="border-2 border-neutral-light-200 bg-neutral-light w-5 h-5 rounded-md">
						<CheckIcon className="text-primary" />
					</div>
				),
				icon: <div className="border-2 border-neutral-light-200 bg-neutral-light w-5 h-5 rounded-md" />,
				disableRipple: true,
			},
			styleOverrides: {
				root: ({ ownerState }) =>
					({
						color: colors.neutral,
						paddingLeft: 0,
					} as CSSInterpolation),
			},
		},
	},
});

interface IGlobalContext {
	online: boolean;
	pageTitle: string;
	setPageTitle?: any;
	muiTheme: Theme;
	pageLoading: boolean;
	setPageLoading?: any;
}
const initialState: IGlobalContext = {
	online: true,
	pageTitle: '',
	muiTheme: defaultTheme,
	pageLoading: false,
};

const GlobalContext: Context<IGlobalContext> = createContext(initialState);

export const checkOnlineStatus = async (): Promise<boolean> => {
	if (window && !window.navigator.onLine) return false;

	try {
		const response = await fetch('/api/online', { method: 'HEAD' });

		return response.ok;
	} catch (err) {
		return false;
	}
};

// context provider
const AppWrapper = ({ children }: any) => {
	const [isOnline, setIsOnline] = useState(true);
	const [pageTitle, setPageTitle] = useState('');
	const [pageLoading, setPageLoading] = useState(false);
	let globalModalRef: any;
	const [muiTheme, setMuiTheme] = useState<Theme>(defaultTheme);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			checkOnlineStatus().then((isCurrentlyOnline) => {
				console.log('isCurrentlyOnline', isCurrentlyOnline);
				setIsOnline(isCurrentlyOnline);
			});

			window.addEventListener('online', () => {
				console.log('setToOnline');
				setIsOnline(true);
			});

			window.addEventListener('offline', () => {
				console.log('setToOffline');
				setIsOnline(false);
			});
		}

		Modal.registerModal(globalModalRef);
		PackageModal.registerModal(globalModalRef);
	}, [globalModalRef, pageTitle]);

	//const [state, dispatch] = useReducer(combineReducers(user), initialState); // pass more reducers combineReducers(user, blogs, products)
	//const value = { state, dispatch };

	return (
		<GlobalContext.Provider value={{ online: isOnline, pageTitle, setPageTitle, muiTheme, pageLoading, setPageLoading }}>
			<ModalWrapper ref={(ref) => (globalModalRef = ref)} />
			<PackageModalWrapper ref={(ref) => (globalModalRef = ref)} />
			{children}
		</GlobalContext.Provider>
	);
};

const useGlobalContext = () => useContext(GlobalContext);

export { GlobalContext, AppWrapper, useGlobalContext };
