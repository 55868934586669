import { CheckIcon, ExclamationTriangleIcon, MegaphoneIcon, UserIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'next-i18next';
import { MessageT } from '@/utils/alert/alert-context';
import useAlert from '@/utils/alert/use-alert';

export default function Message() {
	const { text, type, setAlert }: { text: string; type: MessageT | string; setAlert: any } = useAlert();

	const { t } = useTranslation();

	const getMessage = (): string => {
		switch (type) {
			case 'success':
				return t('msg-success');
			case 'error':
				return t('msg-error');
			case 'info':
			default:
				return t('msg-info');
		}
	};

	const Icon = (): JSX.Element => {
		const iconStyle = 'h-6 w-6 text-neutral-light';
		let color;
		let icon;

		switch (type) {
			case 'success':
				color = 'bg-success-dark';
				icon = <CheckIcon className={iconStyle} />;
				break;
			case 'error':
				color = 'bg-danger-dark';
				icon = <XMarkIcon className={iconStyle} />;
				break;
			case 'warning':
				color = 'bg-warning-dark';
				icon = <ExclamationTriangleIcon className={iconStyle} />;
				break;
			case 'info':
			default:
				color = 'bg-primary';
				icon = <MegaphoneIcon className={iconStyle} />;
				break;
		}

		return <div className={`rounded-xl p-3 shadow-md ${color}`}>{icon}</div>;
	};

	if (type) {
		return (
			<div
				className="fixed bottom-5 left-8 right-8 min-h-[5rem] bg-neutral-light shadow-md border border-neutral-100 flex gap-5 items-center p-4 rounded-xl z-[52]"
				onMouseDown={(e) => e.stopPropagation()}
			>
				<div>
					<Icon />
				</div>
				<div className="relative text-neutral">
					{text?.length ?
						<p>{text}</p>
					:	<p>{getMessage()}</p>}
				</div>
				<button
					type="button"
					className="absolute right-2 top-2"
					onClick={(e) => setAlert('', '')}
				>
					<XMarkIcon className="w-5 h-5" />
				</button>
			</div>
		);
	} else {
		return <></>;
	}
}
