import { createContext, useState } from 'react';

export type MessageT = 'error' | 'success' | 'info' | 'warning';
const ALERT_TIME = 5000;
const initialState = {
	type: '',
	text: '',
	setAlert: (type: MessageT, text?: string) => undefined,
};

export type AlertFunction = (type: MessageT, text?: string) => void;

interface IAlertContext {
	setAlert: AlertFunction;
	type: string;
	text: string;
}

const AlertContext = createContext<IAlertContext>({
	...initialState,
});

export const AlertProvider = ({ children }: any) => {
	const [text, setText] = useState('');
	const [type, setType] = useState('');

	const setAlert = (type: MessageT, text?: string) => {
		if (text) {
			setText(text);
		}

		setType(type);

		if (type === 'success' || type === 'info') {
			setTimeout(() => {
				setText('');
				setType('');
			}, ALERT_TIME);
		}
	};

	return (
		<AlertContext.Provider
			value={{
				type,
				text,
				setAlert,
			}}
		>
			{children}
		</AlertContext.Provider>
	);
};

export default AlertContext;
