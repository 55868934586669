import { ArrowUturnLeftIcon } from '@heroicons/react/24/solid';
import { useRouter } from 'next/router';

export default function BackButton(props: any) {
	const router = useRouter();

	const handleBack = () => {
		router.back();
	};

	return (
		<button
			onClick={handleBack}
			className={`flex gap-3 items-center ${props.className ?? ''}`}
		>
			{props.text && <span>{props.text}</span>}
			<ArrowUturnLeftIcon className="h-8 w-8" />
		</button>
	);
}
