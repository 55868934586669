import { Combobox as HUICombobox, Listbox as HUIListbox, Transition, Tab as HUITab, Switch, Menu } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { XCircleIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { fileToBase64, removeItems, updateQueryParam } from '@/lib/helpers';
import { Base64File, Modify } from '@/types';
import { DatePicker, IDatePickerProps } from './moment-date';

interface IOptions {
	className?: string;
	style?: any;
	btnClassName?: string;
}

interface IDropdown {
	label: string;
	actions: Array<any>;
	className?: string;
}

export function Dropdown({ actions, label, className }: IDropdown) {
	const dropdownId = label.replace(' ', '');

	return (
		<Menu
			as="div"
			className="relative inline-block text-left"
		>
			<Menu.Button
				className={`border border-primary rounded-lg p-2 flex justify-between items-center  text-neutral group ${className}`}
				data-cy={`dropdown-btn-${dropdownId}`}
			>
				{label}
				<ChevronDownIcon
					className="ml-2 -mr-1 h-5 w-5 text-primary"
					aria-hidden="true"
				/>
			</Menu.Button>
			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items
					as="div"
					className="absolute right-0 origin-top-right bg-neutral-light shadow-md border rounded-md mt-1 z-50 overflow-auto max-h-60"
				>
					{actions.map((action, idx) => (
						<Menu.Item
							key={idx}
							data-cy={`dropdown-${dropdownId}-item-${idx}`}
						>
							{({ active }) =>
								React.cloneElement(action, {
									key: idx,
									className: `${action.props.className} ${
										active ? 'bg-primary text-neutral-light' : 'text-neutral'
									} group flex w-full items-center rounded-md px-2 py-2 text-sm group truncate`,
								})
							}
						</Menu.Item>
					))}
				</Menu.Items>
			</Transition>
		</Menu>
	);
}

export interface ISelectItem {
	id: string | number;
	label: string;
}

interface ISelect {
	id?: string | number;
	value: Array<ISelectItem>;
	label?: string;
	selected?: string | string[];
	onChange: (val: any) => void;
	className?: string;
	disabled?: boolean;
	placeholder?: string;
	error?: boolean;
	multiple?: boolean;
	required?: boolean;
}

export function Select({ onChange, error = false, required = false, ...props }: ISelect) {
	const { t } = useTranslation();
	const [selectButtonWidth, setSelectButtonWidth] = useState<number>(0);
	const [selectTopOffset, setSelectTopOffset] = useState<number>(0);
	const selectButtonRef = useRef<any>();
	const selectDisabled: boolean = props.disabled || !(props.value?.length >= 1);

	useEffect(() => {
		setSelectButtonWidth(selectButtonRef.current?.offsetWidth);
		setSelectTopOffset(
			document.getElementsByClassName('modal-container')?.[0]?.getBoundingClientRect()?.bottom - selectButtonRef.current?.getBoundingClientRect()?.bottom,
		);
	}, [selectButtonRef]);

	if (!props.value?.length) {
		return <></>;
	}

	const emptyItem: ISelectItem = {
		id: '',
		label: t('no-value') ?? 'undefined',
	};

	const getSelected = () => {
		if (props.selected) {
			if (Array.isArray(props.selected)) {
				return props.value
					.filter((v) => props.selected?.includes(String(v.id)))
					.map((s) => s.label)
					.join(', ');
			} else {
				return props.value.find((v) => String(v.id) === props.selected)?.label;
			}
		} else {
			return props.placeholder;
		}
	};

	const itemList = !required && !props.multiple ? [emptyItem, ...props.value] : props.value;

	const SelectOptions = ({ style, btnClassName }: IOptions) => {
		const getButtonOffset = () =>
			selectButtonRef.current?.getBoundingClientRect()?.bottom - document.getElementsByClassName('modal-container')?.[0]?.getBoundingClientRect()?.top;

		return (
			<Transition
				enter="transition duration-100 ease-out"
				enterFrom="transform scale-95 opacity-0"
				enterTo="transform scale-100 opacity-100"
				leave="transition duration-75 ease-out"
				leaveFrom="transform scale-100 opacity-100"
				leaveTo="transform scale-95 opacity-0"
				className={`z-[51] w-full ${btnClassName}`}
				style={{ maxWidth: `${selectButtonWidth}px`, top: `${getButtonOffset()}px`, ...style }}
			>
				<HUIListbox.Options
					style={{ maxWidth: `${selectButtonRef.current?.offsetWidth}px` }}
					className={
						'max-h-60 overflow-auto rounded-md bg-neutral-light py-1 shadow-lg ring-1 ring-neutral ring-opacity-5 focus:outline-none text-sm z-[51] w-full'
					}
					onMouseDown={(e) => {
						e.stopPropagation();
						e.preventDefault();
					}}
				>
					{itemList.map((item: ISelectItem) => (
						<HUIListbox.Option
							key={String(item.id)}
							value={String(item.id)}
							className={({ active }) =>
								`relative cursor-default select-none py-2 pl-10 pr-4 group ${active ? 'bg-primary !text-neutral-light' : 'text-neutral'}`
							}
							data-cy={`listbox-opt-${item.id}`}
						>
							{({ selected, active }) => (
								<>
									<span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{item.label}</span>

									{selected && (
										<span className={'absolute inset-y-0 left-0 flex items-center pl-3'}>
											<CheckIcon
												className={`h-5 w-5 ${active ? 'text-neutral-light' : 'text-primary'}`}
												aria-hidden="true"
											/>
										</span>
									)}
								</>
							)}
						</HUIListbox.Option>
					))}
				</HUIListbox.Options>
			</Transition>
		);
	};

	return (
		<HUIListbox
			as="div"
			id={String(props.id)}
			onChange={(item) => onChange(item)}
			disabled={selectDisabled}
			className={`flex flex-col ${props.className ?? ''}`}
			multiple={props.multiple}
		>
			{props.label?.length && <HUIListbox.Label className="ml-2">{props.label}</HUIListbox.Label>}
			<HUIListbox.Button
				className={`${
					error ? 'border-danger-dark input-invalid' : 'border-neutral-light-200'
				} flex justify-between rounded-lg border-2 bg-neutral-light py-2 px-3 
                            text-left focus:outline-none focus-visible:border-primary focus-visible:ring-2 
                            focus-visible:ring-neutral-light focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary 
                            text-sm ${selectDisabled ? 'text-neutral-light-200 ' : 'cursor-pointer'}`}
				data-cy={`listbox-btn-${props.id}`}
				ref={selectButtonRef}
			>
				<span className="block truncate">{getSelected()}</span>
				<span className="pointer-events-none inset-y-0 right-0 flex items-center">
					<ChevronUpDownIcon className="h-5 w-5 text-neutral-light-200" />
				</span>
			</HUIListbox.Button>
			{document.getElementsByClassName('modal-container')?.[0] ? (
				<Portal node={document.getElementsByClassName('modal-container')?.[0]}>
					<SelectOptions btnClassName="absolute" />
				</Portal>
			) : (
				<SelectOptions />
			)}
		</HUIListbox>
	);
}

export function Combobox({ onChange, error = false, required = false, ...props }: ISelect) {
	const { t } = useTranslation();
	const selectDisabled: boolean = props.disabled || !(props.value.length >= 1);
	const [query, setQuery] = useState('');
	const [inputName, setInputName] = useState<string>('');

	const [buttonWidth, setButtonWidth] = useState<number>(0);
	const [topOffset, setTopOffset] = useState<number>(0);
	const buttonRef = useRef<any>();

	useEffect(() => {
		const getSelected = (): string => {
			if (props.selected?.length) {
				if (Array.isArray(props.selected)) {
					return props.value
						.filter((v) => props.selected?.includes(String(v.id)))
						.map((s) => s.label)
						.join(', ');
				} else {
					return props.value.find((v) => String(v.id) == props.selected)?.label ?? '';
				}
			} else {
				return '';
			}
		};

		setButtonWidth(buttonRef.current?.offsetWidth);
		setTopOffset(
			buttonRef.current?.getBoundingClientRect()?.bottom - document.getElementsByClassName('modal-container')?.[0]?.getBoundingClientRect()?.top,
		);
		setInputName(getSelected());
	}, [props.selected, buttonRef, props.value, props.placeholder]);

	if (!props.value?.length) {
		return <></>;
	}

	const emptyItem: ISelectItem = {
		id: '',
		label: t('no-value') ?? 'undefined',
	};

	const itemList = !required && !props.multiple ? [emptyItem, ...props.value] : props.value;

	const filteredItems =
		query === ''
			? itemList
			: itemList.filter((item) => String(item.label).toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')));

	const conditionalParams: any = {
		multiple: !!props.multiple,
	};

	const ComboOptions = ({ className, style, btnClassName }: IOptions) => (
		<Transition
			leave="transition ease-in duration-100"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
			afterLeave={() => setQuery('')}
			className={`z-[51] ${btnClassName}`}
			style={style}
		>
			<HUICombobox.Options
				className={`absolute max-h-60 w-full overflow-auto rounded-md bg-neutral-light py-1 shadow-lg ring-1 ring-neutral ring-opacity-5 focus:outline-none text-sm z-[51] ${className}`}
			>
				{filteredItems.length === 0 && query !== '' ? (
					<p className="py-2 px-4">{t('no-items')}</p>
				) : (
					filteredItems.map((item: ISelectItem) => (
						<HUICombobox.Option
							key={String(item.id)}
							value={String(item.id)}
							className={({ active }) =>
								`relative cursor-default select-none py-2 pl-10 pr-4 group ${active ? 'bg-primary !text-neutral-light' : 'text-neutral'}`
							}
							data-cy={`combobox-opt-${item.id}`}
						>
							{({ selected, active }) => (
								<>
									<span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{item.label}</span>

									{selected && (
										<span className={'absolute inset-y-0 left-0 flex items-center pl-3'}>
											<CheckIcon
												className={`h-5 w-5 ${active ? 'text-neutral-light' : 'text-primary'}`}
												aria-hidden="true"
											/>
										</span>
									)}
								</>
							)}
						</HUICombobox.Option>
					))
				)}
			</HUICombobox.Options>
		</Transition>
	);

	return (
		<HUICombobox
			as="div"
			id={props.id}
			onChange={(item) => onChange(item)}
			disabled={selectDisabled}
			className={`flex flex-col relative ${props.className ?? ''}`}
			value={props.selected}
			{...conditionalParams}
		>
			{props.label?.length && <HUICombobox.Label className="ml-2">{props.label}</HUICombobox.Label>}
			<div
				className={`${
					error ? 'border-danger-dark input-invalid' : 'border-neutral-light-200'
				} relative flex flex-col rounded-lg border-2 bg-neutral-light 
                    focus-visible:border-primary focus:outline-none focus-visible:border-primary focus-visible:ring-2 focus-within:border-primary focus:border-primary focus-visible:ring-offset-primary
                    focus-visible:ring-neutral-light focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary ${
						selectDisabled ? 'text-neutral-light-200 ' : 'cursor-pointer'
					}`}
			>
				<HUICombobox.Button
					className="flex justify-between items-center pr-3"
					as="div"
					ref={buttonRef}
				>
					<HUICombobox.Input
						key={inputName}
						className={
							'flex text-left text-sm rounded-lg py-2 px-3 grow !border-transparent !focus:border-transparent !ring-offset-transparent !ring-transparent !focus:ring-0 outline-none'
						}
						displayValue={() => inputName}
						placeholder={props.placeholder}
						onChange={(event) => setQuery(event.target.value)}
						autoComplete="off"
						data-cy={'combobox-input'}
					/>
					<ChevronUpDownIcon
						className="h-5 w-5 text-neutral-light-200"
						aria-hidden="true"
					/>
				</HUICombobox.Button>
			</div>
			{document.getElementsByClassName('modal-container')?.[0] ? (
				<Portal node={document.getElementsByClassName('modal-container')?.[0]}>
					<ComboOptions
						style={{ width: `${buttonWidth}px`, top: `${topOffset}px` }}
						btnClassName="absolute"
					/>
				</Portal>
			) : (
				<ComboOptions />
			)}
		</HUICombobox>
	);
}

interface IToggle {
	id?: string;
	enabled?: boolean;
	label?: string;
	value?: any;
	onChange?: (val: any) => void;
}

export function Toggle({ onChange, ...props }: IToggle) {
	return (
		<Switch.Group>
			{props.label && <Switch.Label>{props.label}</Switch.Label>}
			<Switch
				defaultChecked={props.enabled}
				name={props.id}
				value={props.value ?? '1'}
				as={Fragment}
			>
				{({ checked }) => (
					<button className={`${checked ? 'bg-primary' : 'bg-neutral-light-200'} relative inline-flex h-7 w-[3.5rem] items-center rounded-full`}>
						<span className="sr-only">Enable</span>
						<span className={`${checked ? 'translate-x-8' : 'translate-x-1'} inline-block h-5 w-5 transform rounded-full bg-white transition`} />
					</button>
				)}
			</Switch>
		</Switch.Group>
	);
}

const getTabStyle = (
	selected: boolean,
) => `w-full rounded-lg text-sm font-medium leading-5 ring-neutral-light ring-opacity-60 ring-offset-2 ring-offset-primary focus:outline-none focus:ring-2
    ${selected ? 'bg-neutral-light shadow text-primary' : 'text-neutral-light'}`;

export interface ILinkTabs {
	id: string;
	name: string;
	url: string;
	as?: string;
	disabled?: boolean;
}

export function LinkTabs({ value, className }: { value: Array<ILinkTabs>; className?: string }) {
	const { t } = useTranslation('common');
	const router = useRouter();

	function ConditionalLink({ tab }: { tab: ILinkTabs }) {
		const props = {
			id: tab.id,
			'data-cy': tab.id,
			className: 'flex grow text-center items-center justify-center py-2.5',
		};

		return (
			<>
				{tab.disabled ? (
					<button
						type="button"
						{...props}
						disabled
					>
						{t(tab.name)}
					</button>
				) : (
					<Link
						href={tab.url ?? '#'}
						{...props}
					>
						{t(tab.name)}
					</Link>
				)}
			</>
		);
	}

	return (
		<>
			<ul className={`flex space-x-1 rounded-xl bg-primary p-1 shadow ${className}`}>
				{value.map((tab) => (
					<li
						key={tab.id}
						className={getTabStyle(router.pathname == tab.url)}
					>
						<ConditionalLink tab={tab} />
					</li>
				))}
			</ul>
		</>
	);
}

interface ITabs {
	categories: Array<string>;
	defaultIndex?: number;
	selectedIndex?: number;
	children: any;
	tabClassName?: string;
}

export default function Tabs({ categories, tabClassName, defaultIndex = 0, selectedIndex, children }: ITabs) {
	const router = useRouter();

	return (
		<HUITab.Group
			defaultIndex={defaultIndex}
			selectedIndex={selectedIndex}
			as="div"
			className="flex grow flex-col"
			//onChange={(index) => updateQueryParam(router, 'tab', index)}
		>
			<HUITab.List className={`flex space-x-1 rounded-xl bg-primary p-1 shadow ${tabClassName}`}>
				{categories.map((category: string) => (
					<HUITab
						as="button"
						key={category}
						className={({ selected }) => `${getTabStyle(selected)} py-2.5`}
					>
						{category}
					</HUITab>
				))}
			</HUITab.List>
			<HUITab.Panels
				as="div"
				className="mt-2 flex flex-col grow"
			>
				{children.map((child: any, idx: number) => (
					<HUITab.Panel
						key={idx}
						className="flex flex-col grow"
					>
						{child}
					</HUITab.Panel>
				))}
			</HUITab.Panels>
		</HUITab.Group>
	);
}

// interface IRadioGroup {
//     id?: string;
//     value: Array<IRadio>;
//     label?: string;
//     selected?: string;
//     onChange: Function;
//     required?: boolean;
//     error?: string;
//     onValidation?: Function;
//     className?: string;
// }
export interface IRadio {
	id: string;
	label: string;
	description?: string;
	disabled?: boolean;
}
type IRadioGroup = Modify<
	React.HTMLProps<HTMLInputElement>,
	{
		selected?: string;
		onChange?: (val: any) => void;
		value: Array<IRadio>;
		onValidation?: (valid: boolean) => void;
		error?: string;
	}
>;

export function RadioGroup(props: IRadioGroup) {
	const [selected, setSelected] = useState<string | null>(props.selected ?? null);

	const [error, setError] = useState<string | null>(null);

	// useEffect(() => {
	//     console.log('radio selected', props.selected);
	//     console.log('radio value', props.value);
	//     setSelected(props.selected ?? null);
	// }, [props.selected]);

	if (!props.value?.length) {
		return <></>;
	}

	const onValidation = (text: string | null) => {
		setError(text);
		props.onValidation && props.onValidation(!text?.length);
	};

	const handleChange = (radio: IRadio) => {
		setSelected(radio.id);
		props.onChange?.(radio.id);
	};

	return (
		<div className="input-group flex flex-col">
			<div className={`flex gap-2 ${props.className}`}>
				{props.value.map((radio: IRadio, idx: number) => (
					<div
						key={radio.id}
						className="select-none"
					>
						<input
							type="radio"
							id={`${radio.id}`}
							name={`${props.id}`}
							onChange={() => handleChange(radio)}
							className="absolute opacity-0 max-w-0"
							value={radio.id}
							checked={radio.id === selected}
							required={props.required}
							onInvalid={(e: any) => onValidation(e?.target?.validationMessage)}
							onInput={() => onValidation(null)}
						/>
						<label
							className="flex gap-2 items-center"
							htmlFor={`${radio.id}`}
						>
							<span
								className={`${
									error ? 'border-danger-dark input-invalid' : 'border-neutral-light-200'
								} radio border-2 min-w-min min-h-min w-5 h-5 rounded-full bg-neutral-light flex items-center justify-center p-0.5`}
								aria-hidden="true"
							>
								<span className={`w-full h-full rounded-full ${radio.id === selected ? 'bg-primary' : ''}`} />
							</span>
							<span>{radio.label}</span>
						</label>
					</div>
				))}
			</div>
			{error && <span className="text-danger-dark text-xs">{props.error ?? error}</span>}
		</div>
	);
}

// interface ICheckGroup {
//     id?: string;
//     value: Array<ICheck>;
//     label?: string;
//     selected: Array<ICheck>;
//     onChange: Function;
//     required?: boolean;
//     error?: string;
//     onValidation?: Function;
//     className?: string;
// }
export interface ICheck {
	id: string;
	label: string;
	disabled?: boolean;
	checked?: boolean;
}
type ICheckGroup = Modify<
	React.HTMLProps<HTMLInputElement>,
	{
		selected: Array<ICheck>;
		onChange: (val: any) => void;
		value: Array<ICheck>;
		onValidation?: (valid: boolean) => void;
		error?: string;
	}
>;

export function CheckGroup({ selected = [], onChange, ...props }: ICheckGroup) {
	const [error, setError] = useState<string | null>(props.error ?? null);

	const setNewSelected = (check: ICheck) => {
		const itemIndex = selected.findIndex((item) => item.id === check.id);

		if (itemIndex >= 0) {
			const newArray = selected.concat();
			newArray.splice(itemIndex, 1);

			onChange(newArray);
		} else {
			onChange([...selected, check]);
		}
	};

	const onValidation = (text: string | null) => {
		setError(text);
		props.onValidation && props.onValidation(!text?.length);
	};

	return (
		<div className="input-group flex flex-col">
			<div
				className={`select-none flex flex-col gap-2 ${props.className}`}
				id={props.id}
			>
				{props.value.map((check: ICheck, idx: number) => (
					<div key={check.id}>
						<input
							type="checkbox"
							name={`${props.id}`}
							id={`${check.id}`}
							onChange={() => setNewSelected(check)}
							className="absolute opacity-0 max-w-0 hidden"
							checked={selected.some((c) => c.id === check.id)}
							required={props.required}
							onInvalid={(e: any) => onValidation(e?.target?.validationMessage)}
							onInput={() => onValidation(null)}
						/>
						<label
							className="check-group flex items-center gap-2"
							data-cy={`check-btn-${check.id}`}
							htmlFor={`${check.id}`}
						>
							<span
								className={`${
									error ? 'border-danger-dark input-invalid' : 'border-neutral-light-200'
								} checkbox border-2 rounded-md bg-neutral-light flex items-center justify-center`}
								aria-hidden="true"
							>
								<CheckIcon
									className={`w-5 h-5 p-0.5 ${selected.some((item) => item.id === check.id) ? 'text-primary' : 'text-neutral-light'}`}
								/>
							</span>
							<span>{check.label}</span>
						</label>
					</div>
				))}
			</div>
			{error && <span className="text-danger-dark text-xs">{props.error ?? error}</span>}
		</div>
	);
}

// interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
//     id?: string;
//     type: string;
//     value?: any;
//     placeholder?: string;
//     defaultValue?:any;
//     minVal?: number|string;
//     maxVal?: number|string;
//     minLength?:
//     required?: boolean;
//     error?: string;
//     className?: string;
//     onValidation?: Function;
//     disabled?: boolean;
// }

interface IInput
	extends Modify<
		React.HTMLProps<HTMLInputElement>,
		{
			onChange?: (val?: React.ChangeEvent<HTMLInputElement> | null) => void;
			onValue?: (val: string | number | readonly string[] | undefined) => void;
		}
	> {
	onValidation?: (valid: boolean) => void;
	error?: string;
	hasError?: boolean;
	hide?: boolean;
}

export const Input = React.forwardRef(function Input({ type, onChange, onValue, value, defaultValue, onValidation: validated, ...props }: IInput, ref) {
	const isMounted = useRef(false);

	const [error, setError] = useState<string | null>(null);
	const [prevVal, setPrevVal] = useState<string | number | readonly string[] | null>(value ?? null);
	//const [conditionalProps, setConditionalProps] = useState<any>({ value });
	//const [query, setQuery] = useState<string | number | readonly string[] | null>(value ?? defaultValue ?? null);
	//const [changeEvent, setChangeEvent] = useState<null|React.ChangeEvent<HTMLInputElement>>(null);

	const inputRef = useRef<HTMLInputElement>(null);

	React.useImperativeHandle(
		ref,
		() => ({
			focus() {
				inputRef.current?.focus();
			},
			scrollIntoView() {
				inputRef.current?.scrollIntoView();
			},
		}),
		[],
	);

	const onValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
		const text = e?.target?.validationMessage;

		setError(text);
		validated && validated(!text?.length);
	};

	const resetValidation = useCallback(() => {
		setError(null);
		validated && validated(true);
	}, [validated]);

	useEffect(() => {
		if (!isMounted.current) {
			isMounted.current = true;
		} else if (value != prevVal) {
			setPrevVal(value ?? null);
			onValue?.(value);
		}
	}, [value]);

	return (
		<div className={`input-group flex flex-col ${props.hide && 'w-0 h-0 opacity-0 border-none absolute'}`}>
			<input
				id={props.id}
				required={props.required}
				onInvalid={onValidation}
				onInput={resetValidation}
				name={props.id}
				type={type}
				placeholder={props.placeholder}
				data-cy={`input-${type}-${props.id}`}
				className={`${
					error || props.hasError ? 'border-danger-dark input-invalid' : 'border-neutral-light-200 focus:border-primary'
				} border-2 focus:outline-none focus:shadow-outline rounded-md p-1 text-neutral placeholder:text-neutral-light-300 ${props.className}`}
				onChange={(e) => onChange?.(e)}
				min={props.min}
				max={props.max}
				minLength={props.minLength}
				maxLength={props.maxLength}
				disabled={props.disabled}
				readOnly={props.readOnly}
				accept={props.accept}
				pattern={props.pattern}
				defaultValue={defaultValue}
				value={value}
				//{...conditionalProps}
				ref={inputRef}
			/>
			{error && <span className="text-danger-dark text-xs">{props.error ?? error}</span>}
		</div>
	);
});

type IUploadInput = Modify<
	IInput,
	{
		onChange: (val: Base64File[]) => void;
		value?: Base64File[];
	}
>;

export function UploadInput(props: IUploadInput) {
	const [files, setFiles] = useState<Base64File[]>([]);

	useEffect(() => {
		if (props.value) {
			setFiles(props.value);
		}
	}, [props.value]);

	const onFileChange = async (fileList: FileList) => {
		const uploadedFiles: Base64File[] = [];

		for (const file of fileList) {
			const base64 = await fileToBase64(file);

			uploadedFiles.push({
				name: file.name,
				type: file.type,
				file: base64,
			});
		}

		const newFiles: Base64File[] = props.multiple ? Array.from(new Set([...files, ...uploadedFiles])) : uploadedFiles;

		setFiles(newFiles);
		props.onChange(newFiles);
	};

	const removeFile = (file: Base64File) => {
		const newFiles = removeItems(files, [file]);
		setFiles(newFiles);
		props.onChange(newFiles);
	};

	const handleChange = (val?: React.ChangeEvent<HTMLInputElement> | null) => {
		if (val?.target?.files) {
			onFileChange(val.target.files);
		}
	};

	return (
		<div className="file-upload">
			<Input
				{...props}
				type="file"
				value={undefined}
				onChange={handleChange}
				ref={undefined}
			/>
			{files.map((f) => (
				<div
					className="file-container flex gap-2 items-center"
					key={f.name}
				>
					<button
						type="button"
						onClick={() => removeFile(f)}
					>
						<XCircleIcon className="h-7 w-7 text-primary" />
					</button>
					<p className="truncate">{f.name}</p>
				</div>
			))}
		</div>
	);
}

interface IDatePickerInput extends IDatePickerProps {
	onValidation?: (valid: boolean) => void;
	error?: string;
}

export function DatePickerInput({ error, onValidation: validated, selected, ...props }: IDatePickerInput) {
	const { t } = useTranslation();

	const [errorMsg, setError] = useState<string | null>(null);

	const onValidation = useCallback(
		(text: string | null) => {
			setError(text);
			validated && validated(!text?.length);
		},
		[validated],
	);

	useEffect(() => {
		onValidation(null);
	}, [onValidation, selected]);

	return (
		<div className="input-group flex flex-col">
			<DatePicker
				className={`${
					errorMsg ? 'border-danger-dark input-invalid' : 'border-neutral-light-200 focus:border-primary'
				} border-2 focus:outline-none focus:shadow-outline rounded-md !p-1 text-neutral placeholder:text-neutral-light-300 w-full ${
					props.className ?? ''
				}`}
				selected={selected}
				onInputError={(e: any) => onValidation(e?.target?.validationMessage)}
				onChangeRaw={() => {
					onValidation(null);
				}}
				placeholderText={props.placeholderText ?? t('select-time') ?? undefined}
				{...props}
			/>
			{error && <span className="text-danger-dark text-xs">{error ?? errorMsg}</span>}
		</div>
	);
}

// interface ITextArea {
//     id?: string;
//     value?: any;
//     placeholder?: string;
//     defaultValue?:any;
//     required?: boolean;
//     error?: string;
//     onValidation?: Function;
//     className?: string;
//     onChange?: Function;
// }
interface ITextArea extends React.HTMLProps<HTMLTextAreaElement> {
	onValidation?: (valid: boolean) => void;
	error?: string;
}

export function TextArea(props: ITextArea) {
	const [error, setError] = useState<string | null>(null);

	const onValidation = (text: string | null) => {
		setError(text);
		props.onValidation && props.onValidation(!text?.length);
	};

	return (
		<div className="input-group flex flex-col">
			<textarea
				id={props.id}
				required={props.required ?? false}
				onInvalid={(e: any) => onValidation(e?.target?.validationMessage)}
				onInput={() => onValidation(null)}
				defaultValue={props.defaultValue}
				name={props.id}
				value={props.value}
				placeholder={props.placeholder}
				onChange={(val) => props.onChange?.(val)}
				data-cy={`text-area-${props.id}`}
				className={`${
					error ? 'border-danger-dark input-invalid' : 'border-neutral-light-200 focus:border-primary'
				} border-2 focus:outline-none focus:shadow-outline rounded-md p-1 min-h-[7rem] min-w-[7rem] max-w-full max-h-72 resize text-neutral placeholder:text-neutral-light-300 ${
					props.className
				}`}
				minLength={props.minLength}
				maxLength={props.maxLength}
				disabled={props.disabled}
			/>
			{error && <span className="text-danger-dark text-xs">{props.error ?? error}</span>}
		</div>
	);
}
