import 'reflect-metadata';
import { NextPage } from 'next';
import Head from 'next/head';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import { ReactElement, ReactNode, useEffect } from 'react';
import { SWRConfig, SWRConfiguration } from 'swr';
import Layout, { ILayoutProps } from '@/components/layout';
import Message from '@/components/message';
import { AppWrapper } from '@/lib/global-context';
import { fetcher } from '@/lib/helpers';
import nextI18NextConfig from '@/next-i18next.config';
import { AlertProvider } from '@/utils/alert/alert-context';
import useAlert from '@/utils/alert/use-alert';
import useResizeObserver from '@/utils/useResizeObserver';
import type { AppProps } from 'next/app';
import '@/styles/style.css';

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
	getLayout?: (page: ReactElement) => ReactNode;
	getLayoutProps?: ILayoutProps;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
	session: Session;
};

const ContentWrapSWR = (props: any) => {
	const { setAlert } = useAlert();

	const config: SWRConfiguration = {
		refreshInterval: Number(process.env.NEXT_PUBLIC_REFRESH_INTERVAL),
		fetcher: fetcher,
		onError: (error: Error) => {
			setAlert('error', error.message);
		},
	};

	return <SWRConfig value={config}>{props.children}</SWRConfig>;
};

function App({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) {
	// const [ appRef, { contentRect } ] = useResizeObserver();

	// Use the layout defined at the page level, if available
	const getLayout = Component.getLayout ?? ((page) => <Layout {...Component.getLayoutProps}>{page}</Layout>);

	// useEffect(() => {
	//     if (document && window) {
	//         const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
	//         const pageWidth = window.innerWidth - scrollbarWidth;

	//         if (appRef) {

	//             console.log('appRef.current', appRef.current);
	//             appRef.current?.style.setProperty('--app-height', `${window.innerHeight}px`);
	//             appRef.current?.style.setProperty('--app-width', `${pageWidth}px`);
	//         }

	//         console.log('pageWidth', pageWidth);
	//     }
	// }, [contentRect, appRef]);

	return (
		<SessionProvider
			session={session}
			refetchInterval={5 * 60}
			refetchOnWindowFocus={true}
		>
			<div
				className="flex flex-col grow"
				id="app-content" /*ref={boxRef}*/
			>
				<AppWrapper>
					<Head>
						<meta
							name="viewport"
							content="width=device-width, initial-scale=1"
						/>
						<title key="title">{`${process.env.NEXT_PUBLIC_APP_NAME ?? 'Delta'}`}</title>
					</Head>
					<AlertProvider>
						<Message />
						<ContentWrapSWR>{getLayout(<Component {...pageProps} />)}</ContentWrapSWR>
					</AlertProvider>
				</AppWrapper>
			</div>
		</SessionProvider>
	);
}

export default appWithTranslation(App, nextI18NextConfig);
