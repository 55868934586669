import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';
import { useGlobalContext } from '@/lib/global-context';

export const PageSpinner = (): ReactElement => (
	<div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center z-[51] bg-white/90">
		<svg
			className="animate-rotate h-12 w-12"
			viewBox="0 0 50 50"
		>
			<circle
				className="animate-dash stroke-primary"
				strokeLinecap="round"
				cx="25"
				cy="25"
				r="20"
				fill="none"
				strokeWidth="5"
			/>
		</svg>
	</div>
);

export default function PageLoader(): ReactElement {
	const router = useRouter();
	const { pageLoading, setPageLoading } = useGlobalContext();

	const isBrowser = typeof window !== 'undefined';
	//const [initialRouteTracked, setInitialRouteTracked] = useState(false);

	const handleStart = (url: string) => setPageLoading(true);
	const handleComplete = (url: string) => setPageLoading(false);

	// if (isBrowser && !initialRouteTracked && window.location.search === "") {
	//     setInitialRouteTracked(true);
	// }

	// use the setup from the examples directory as normal
	useEffect(() => {
		router.events.on('routeChangeStart', handleStart);
		router.events.on('routeChangeComplete', handleComplete);
		router.events.on('routeChangeError', handleComplete);

		return () => {
			router.events.off('routeChangeStart', handleStart);
			router.events.off('routeChangeComplete', handleComplete);
			router.events.off('routeChangeError', handleComplete);
		};
	}, [router.events]);

	return pageLoading ? <PageSpinner /> : <></>;
}
