import nl from 'date-fns/locale/nl';
import moment from 'moment-timezone';
import { SyntheticEvent } from 'react';
import ReactDatePicker, { ReactDatePickerProps, registerLocale, setDefaultLocale } from 'react-datepicker';
import Moment, { MomentProps } from 'react-moment';
import 'moment/locale/nl';
import 'react-datepicker/dist/react-datepicker.css';

export enum DatePickerFormat {
	date = 'yyyy-MM-DD',
	time = 'HH:mm',
	datetime = 'yyyy-MM-DDTHH:mm',
	datetimeNoT = 'yyyy-MM-DD HH:mm',
	dateZ = 'yyyy-MM-DDZ',
	timeZ = 'HH:mmZ',
	datetimeZ = 'yyyy-MM-DDTHH:mmZ',
	datetimeNoTZ = 'yyyy-MM-DD HH:mmZ',
	dateUTC = 'yyyy-MM-DD [UTC]',
	timeUTC = 'HH:mm [UTC]',
	datetimeUTC = 'yyyy-MM-DDTHH:mm [UTC]',
	datetimeNoTUTC = 'yyyy-MM-DD HH:mm [UTC]',
}

export function getMomentDefault(globalLocale?: string, globalTimezone?: string) {
	// Sets the moment instance to use.
	Moment.globalMoment = moment;

	//Moment.globalLocale = globalLocale ?? 'nl';

	// Set the timezone for every instance.
	//Moment.globalTimezone = globalTimezone ?? 'Europe/Amsterdam';

	// Set the output timezone for local for every instance.
	Moment.globalLocal = true;

	// Use a <span> tag for every react-moment instance.
	Moment.globalElement = 'span';

	// Upper case all rendered dates.
	// Moment.globalFilter = (d:any) => {
	//     return d.toUpperCase();
	// };
	registerLocale('nl', nl);
	setDefaultLocale('nl');
}

// export class MomentDateProps implements MomentProps {
//   utc: boolean = true;
// }

export const momentDateDefaults: MomentProps = {
	// utc: true,
	format: 'DD-MM-YYYY HH:mm',
	local: true,
};

export function dateIsBefore(date: string, compareDate?: string) {
	// const isBefore = moment.utc(date).isSameOrBefore(compareDate ? moment.utc(compareDate) : moment());
	const isBefore = moment(date).isSameOrBefore(compareDate ? moment(compareDate) : moment());

	return isBefore;
}

export function dateIsAfter(date: string, compareDate?: string) {
	// const isBefore = moment.utc(date).isSameOrAfter(compareDate ? moment.utc(compareDate) : moment());
	const isBefore = moment(date).isSameOrAfter(compareDate ? moment(compareDate) : moment());

	return isBefore;
}

export function getTodayString(format: DatePickerFormat, toUtc = true, includeTimezone = false) {
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	if (toUtc) {
		if (includeTimezone) {
			return moment.utc().tz(timeZone).format(format);
		}
		return moment.utc().format(format);
	} else {
		if (includeTimezone) {
			return moment().tz(timeZone).format(format);
		}
		return moment().format(format);
	}
}

export function getDateString(date?: moment.MomentInput, props?: MomentProps, toUtc = true, includeTimezone = false) {
	const momentProps = { ...momentDateDefaults, ...props };
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	if (!date || (typeof date == 'string' && !date.length)) {
		//return moment().format(momentProps.format);
		return '';
	}

	if (toUtc) {
		if (includeTimezone) {
			return moment.utc(date).tz(timeZone).format(momentProps.format);
		}
		return moment.utc(date).format(momentProps.format);
	} else {
		if (includeTimezone) {
			return moment(date).tz(timeZone).format(momentProps.format);
		}
		return moment(date).format(momentProps.format);
	}
}

export function getUtcToLocalDateString(date?: moment.MomentInput, props?: MomentProps) {
	const momentProps = { ...momentDateDefaults, ...props };

	if (!date || (typeof date == 'string' && !date.length)) {
		return '';
	}

	return moment.utc(date).local().format(momentProps.format);
}

export function getDate(date?: moment.MomentInput, props?: MomentProps, toUtc = false): Date | null {
	const momentProps = { ...momentDateDefaults, ...props };

	if (!date || (typeof date == 'string' && !date.length)) {
		//return moment().format(momentProps.format);
		return null;
	}

	if (toUtc) {
		return moment.utc(date, momentProps.format).toDate() ?? null;
	} else {
		return moment(date, momentProps.format).toDate() ?? null;
	}
}

export default function MomentDate({ children, ...props }: MomentProps & { children: any }) {
	const momentProps = { ...momentDateDefaults, ...props };

	return <Moment {...momentProps}>{children}</Moment>;
}

export function sortOnDate(items: Array<any>, prop: any, invert = false) {
	if (items?.length) {
		return invert ? items.sort((a, b) => moment(a[prop]).diff(b[prop])) : items.sort((a, b) => moment(b[prop]).diff(a[prop]));
	} else {
		return [];
	}
}

export interface IDatePickerProps extends ReactDatePickerProps {
	dateType?: 'time' | 'date' | 'datetime';
}

const reactDatePickerDefaults: IDatePickerProps = {
	showIcon: true,
	dateType: 'datetime',
	timeIntervals: 1,
	onChange: function (date: Date | null, event: SyntheticEvent<any, Event> | undefined): void {
		throw new Error('Function not implemented.');
	},
};

export function DatePicker(props: IDatePickerProps) {
	const dateProps = { ...reactDatePickerDefaults, ...props };

	const getDateFormatProps = () => {
		const dfProps: any = {};

		switch (props.dateType) {
			case 'date':
				dfProps.dateFormat = props.dateFormat ?? 'dd-MM-yyyy';
				break;
			case 'time':
				dfProps.showTimeSelectOnly = true;
				dfProps.showTimeSelect = true;
				dfProps.timeIntervals = props.timeIntervals ?? 1;
				dfProps.dateFormat = props.dateFormat ?? 'HH:mm';
				break;
			case 'datetime':
			default:
				dfProps.showTimeSelect = true;
				dfProps.dateFormat = props.dateFormat ?? 'dd-MM-yyyy HH:mm';
				break;
		}

		return dfProps;
	};

	return (
		<>
			<ReactDatePicker
				icon={<></>}
				className={
					dateProps.className ??
					'border-neutral-light-200 focus:border-primary border-2 focus:outline-none focus:shadow-outline rounded-md !p-1 text-neutral placeholder:text-neutral-light-300'
				}
				{...dateProps}
				showtime
				onChange={props.onChange}
				{...getDateFormatProps()}
			/>
		</>
	);
}
