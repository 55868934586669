/**
 * @type {import('tailwindcss').Config}
 */
module.exports = {
	// @see https://tailwindcss.com/docs/upcoming-changes
	content: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}', './node_modules/iit-ui/**/*.{js,ts,jsx,tsx}'],
	// future: {
	//     removeDeprecatedGapUtilities: true,
	//     purgeLayersByDefault: true,
	// },
	theme: {
		extend: {
			boxShadow: {
				'neumorphic-light': 'inset 2.1px 2.1px 11px #E9E9E9, inset -2.1px -2.1px 11px #FFFFFF',
			},
			colors: {
				// Configure your color palette here
				primary: {
					DEFAULT: process.env.NEXT_PUBLIC_THEME_COLOR,
					// hover: 'rgb(0, 134, 196)',
					light: '#496bba',
					lightest: '#6393ff',
					text: '#FFFFFF',
				},
				secondary: {
					DEFAULT: '#11964B',
					light: '#BDC439',
					text: '#FFFFFF',
				},
				danger: {
					DEFAULT: '#f8d7da',
					darker: '#de9ba1',
					dark: '#f2293b',
				},
				success: {
					DEFAULT: '#d4edda',
					darker: '#a7d6b2',
					dark: '#318f47',
				},
				warning: {
					DEFAULT: '#f5cc6c',
					dark: '#b38720',
				},
				background: '#F8F8F8',
				neutral: {
					DEFAULT: '#000000',
					lighter: '#5A5A5A',
					light: '#FFFFFF',
					'light-100': '#DEDEDE',
					'light-200': '#C3C3C3',
				},
			},
			keyframes: {
				dash: {
					'0%': {
						'stroke-dasharray': '1, 150',
						'stroke-dashoffset': '0',
					},
					'50%': {
						'stroke-dasharray': '90, 150',
						'stroke-dashoffset': '-35',
					},
					'100%': {
						'stroke-dasharray': '90, 150',
						'stroke-dashoffset': '-124',
					},
				},
				rotate: {
					'100%': { transform: 'rotate(360deg)' },
				},
			},
			animation: {
				dash: 'dash 3s ease-in-out infinite',
				rotate: 'rotate 2s linear infinite',
			},
		},
	},
	variants: {},
	// plugins: [
	//     require( 'tailwindcss' ),
	//     require( 'autoprefixer' )
	// ]
};
